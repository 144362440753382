var _ = require('lodash');

/**
 * @example example video tag. Each dataset is optional. Notice that the source is empty :
 *  <video class="js-video-query"
 *          data-xs="https://url-xm"
 *          data-sm="https://url-sm"
 *          data-md="https://url-md"
 *          data-lg="https://url-lg"
 *          data-xl="https://url-xl"
 *          data-xxl="https://url-xxl"
 *          data-xxxl="https://url-xxl"
 *          src=""></video>
/*

/**
 * Takes the screen pixel width based and returns current breakpoint
 * @param {number} screenWidth screen pixel width
 * @returns {number} The breakpoint number
 */
function getBreakPoint(screenWidth) {
    const breakpoints = [
        {
            min: 0,
            name: 0
        },
        {
            min: 544,
            name: 100
        },
        {
            min: 768,
            name: 200
        },
        {
            min: 1024,
            name: 300
        },
        {
            min: 1150,
            name: 400
        },
        {
            min: 1440,
            name: 500
        },
        {
            min: 1920,
            name: 600
        }
    ];
    return breakpoints.reduce((size, breakpoint) => (breakpoint.min < screenWidth ? breakpoint.name : size), '');
}

/**
 * Matches the breakpoint size and matches it to the elements dataset url size
 * @param {number} size Breakpoint size
 * @param {Element} video video element
 * @returns {string} video url

 */
function currentSizeMatch(size, video) {
    const key = {
        0: 'xs',
        100: 'sm',
        200: 'md',
        300: 'lg',
        400: 'xl',
        500: 'xxl',
        600: 'xxxl'
    };
    const sets = video.dataset;
    return Object.keys(key).reduce((acc, cur) => {
        if (size >= cur) {
            const url = sets[key[cur]];
            if (url) {
                return url;
            }
        }
        return acc;
    }, null);
}

function updatedVideoSrc(videos, size) {
    videos.forEach(video => {
        const newSrc = currentSizeMatch(size, video);
        const curSrc = video.src;

        if (curSrc !== newSrc && newSrc) {
            video.src = newSrc;
        }
    });
}

/**
 * Acts like a css media query for video elements.
 * Loading the appropriate video based on screen size.
 */
function videoMediaQuery() {
    const videos = Array.from(document.querySelectorAll('.js-video-query'));

    if (videos.length > 0) {
        let currentSize = getBreakPoint(window.innerWidth);

        // Update video src on page load
        updatedVideoSrc(videos, currentSize);

        window.addEventListener('resize', _.throttle(() => {
            const newSize = getBreakPoint(window.innerWidth);

            // If we have a new widow size
            if (currentSize !== newSize) {
                currentSize = newSize;
                updatedVideoSrc(videos, currentSize);
            }
        }, 500));
    }
}

module.exports = {
    videoMediaQuery: videoMediaQuery
};
