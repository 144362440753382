'use strict';

var shippingBanner = {
    init: function () {
        $('.c-header-banner button.close, .c-header-promo button.close').on('click', function (e) {
            document.cookie = 'hideHeaderBanner=true; path=/';
            $('.c-header-banner, .c-header-promo').slideUp();
        });
    }
};

module.exports = shippingBanner;
