/* global liveagent */
/* eslint no-underscore-dangle: 'off' */
'use strict';

var liveagentObj = window.liveagentObj;
window.liveagentObj = undefined;

function initializeLiveAgent() {
    liveagent.showWhenOnline(liveagentObj.buttonId, document.getElementById('liveagent_button_online_' + liveagentObj.buttonId));
    liveagent.showWhenOffline(liveagentObj.buttonId, document.getElementById('liveagent_button_offline_' + liveagentObj.buttonId));
    liveagent.init(liveagentObj.url, liveagentObj.orgId, liveagentObj.deploymentId);

    $('.chatbutton').on('click', function (e) {
        liveagent.startChat(liveagentObj.buttonId);
    });

    var action = $('.page').attr('data-action');
    if (action) {
        $('.chatbutton').addClass('action-' + action);
    }
}

if (window.liveagent) {
    initializeLiveAgent();
} else {
    window._laq = window._laq || [];
    window._laq.push(function () {
        initializeLiveAgent();
    });
}
