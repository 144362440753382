'use strict';

var processThumbnailActiveStateQuickView = function ($thumbnail, thumbnailIndex) {
    if ($('.js-pdp-carousel').length) {
        $('.js-pdp-carousel').slick("slickGoTo", thumbnailIndex);

        // Toggle aria-current state
        $('.js-pdp-carousel-thumb').find('.js-slide-indicator.active').attr('aria-current', false);
        $($thumbnail).attr('aria-current', 'true');

        // Toggle active state
        $('.js-pdp-carousel-thumb').find('.js-slide-indicator').removeClass('active');
        $($thumbnail).addClass('active');
    }
};

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    if (data.resources && data.resources.numberOfItems) {
        $('.number-of-items').empty().append(data.resources.numberOfItems);
    }
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });

    if (data.totals.cartType.isOnlyBospiOrder) {
        $('.order-pickup').removeClass('d-none');
        $('.shipping-cost-oder-pickup').addClass('d-none');
        $('.only-shipping-cost').addClass('d-none');
        $('.js-shipping-method-selection-cart').addClass('d-none');
    } else if (data.totals.cartType.isMixedCart) {
        $('.only-shipping-cost').addClass('d-none');
        $('.order-pickup').addClass('d-none');
        $('.shipping-cost-oder-pickup').removeClass('d-none');
        $('.js-shipping-method-selection-cart').removeClass('d-none');
    } else {
        $('.only-shipping-cost').removeClass('d-none');
        $('.shipping-cost-oder-pickup').addClass('d-none');
        $('.order-pickup').addClass('d-none');
        $('.js-shipping-method-selection-cart').removeClass('d-none');
    }
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.js-minicart').empty();
            $('.js-minicart').modal('hide');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}


module.exports = function () {
    $('body').on('click', '.js-remove-product', function (e) {
        e.preventDefault();
        var $this = $(this);
        var productID = $this.data('pid');
        var url = $this.data('action');
        var uuid = $this.data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    $('.cart').empty().append('<div class="row"> ' +
                        '<div class="col-12 text-center"> ' +
                        '<h1>' + data.basket.resources.emptyCartMsg + '</h1> ' +
                        '</div> ' +
                        '</div>'
                    );
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.js-minicart').empty();
                    $('.js-minicart').spinner().start();
                    $('.js-minicart').modal('hide');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    var suuid = $this.parents('.js-card-product-info').data('shipment');
                    $('.uuid-' + uuid).remove();
                    if ($('.shipment-' + suuid).length < 2) {
                        $('.shipment-' + suuid).parents('.js-product-card-container').remove();
                    }
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                }
                $('body').trigger('cart:update', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('.optional-promo').off('click').click(function (e) {
        e.preventDefault();
        $(this).toggleClass("is-expand");
        $('.promo-code-form').toggle(200);
    });

    $(document).off('click', '.select-bonus-product')
    .on('click', '.select-bonus-product', function () {
        var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
        var pid = $(this).data('pid');
        var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
        var submittedQty = parseInt($(this).parents('.choice-of-bonus-product').find('.bonus-quantity-select').val(), 10);
        var totalQty = 0;
        $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
            totalQty += $(this).data('qty');
        });
        totalQty += submittedQty;
        var optionID = $(this).parents('.choice-of-bonus-product').find('.product-option').data('option-id');
        var valueId = $(this).parents('.choice-of-bonus-product').find('.options-select option:selected').data('valueId');
        if (totalQty <= maxPids) {
            var selectedBonusProductHtml = ''
            + '<div class="selected-pid row" '
            + 'data-pid="' + pid + '"'
            + 'data-qty="' + submittedQty + '"'
            + 'data-optionID="' + (optionID || '') + '"'
            + 'data-option-selected-value="' + (valueId || '') + '"'
            + '>'
            + '<div class="col-sm-11 col-9 bonus-product-name" >'
            + $choiceOfBonusProduct.find('.product-name').html()
            + '</div>'
            + '<div class="col-1 st-product-quickview__remove-bonus-product"><i class="fa fa-times" aria-hidden="true"></i></div>'
            + '</div>'
            ;
            $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
            $('.pre-cart-products').html(totalQty);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        } else {
            $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
        }
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        var $pdpCarouselThumbs = $('.js-pdp-carousel-thumb');
        // If the thumbnail slider has less than 6 slides, we need to treat this as if it's not
        // a carousel.  So, we have to modify a bunch of aria attributes.  ADA-106
        $($pdpCarouselThumbs).on('init', function () {
            if (!$($pdpCarouselThumbs).hasClass('js-pdp-carousel-thumb--more-than-six')) {
                $($pdpCarouselThumbs).removeAttr('role').removeAttr('aria-label');
                $($pdpCarouselThumbs).find('.slick-instructions').attr('aria-hidden', true);
                $($pdpCarouselThumbs).find('.slick-slide').attr('role', 'listitem');
                $($pdpCarouselThumbs).find('.slick-track').attr('role', 'list');
            }
        });

        $('.js-pdp-carousel:not(.slick-initialized)').slick();
        $('.js-pdp-carousel-thumb:not(.slick-initialized)').slick();

        $('.js-pdp-carousel').on('afterChange', function (e, slick, currentSlide) {
            var $thumbnail = $('.c-image-carousel__thumbnails__item .js-slide-indicator').eq(currentSlide);
            var thumbnailIndex = $($thumbnail).data('slide-to');

            processThumbnailActiveStateQuickView($thumbnail, thumbnailIndex);
        });
    });

    $('body').on('change', '.js-fulfillment-method', function () {
        var $this = $(this);
        var $cardprodinfo = $this.parents('.js-card-product-info');
        var url = $cardprodinfo.find('div.js-sourcing-locations').attr('data-action');
        var sourcingLocation = $this.attr('data-value-id');
        var pickupID = $cardprodinfo.find('.js-fulfillment-method-store').attr('data-value-id');
        var ship = $cardprodinfo.find('.js-fulfillment-method-ship').attr('data-value-id');

        var uuid = $cardprodinfo.find('.js-remove-product').attr('data-uuid');
        var store = $(this).attr('data-store');
        var odOrderType = $this.attr('data-isodordertype');
        var dataObj = {
            sourcingLocation: sourcingLocation,
            uuid: uuid,
            pickupID: pickupID,
            ship: ship
        };
        $.ajax({
            url: url,
            type: 'post',
            data: dataObj,
            success: function success(data) {
                if (data.sourcingLocationUpdated && data.sourcingLocationUpdated.uuid) {
                    $cardprodinfo.find('.js-remove-product').attr('data-uuid', data.sourcingLocationUpdated.uuid);
                    $cardprodinfo.removeClass(function (index, className) {
                        return (className.match(/(^|\s)uuid-\S+/g) || []).join(' ');
                    });
                    updateCartTotals(data.basket);
                    $cardprodinfo.addClass('uuid-' + data.sourcingLocationUpdated.uuid);
                    if (odOrderType === 'PIS') {
                        $cardprodinfo.prevAll('.row').find('.js-card-product-info-header')
                        .find('.js-shipping-fulfillment-method')
                        .addClass('d-none');
                        $cardprodinfo.prevAll('.row').find('.js-card-product-info-header')
                        .find('.js-order-pick-up-fulfillment-method')
                        .removeClass('d-none');
                        $cardprodinfo.prevAll('.row').find('.js-card-product-info-header')
                        .find('.js-order-pick-up-fulfillment-method .js-product-card-store-name')
                        .text(store);
                    } else {
                        $cardprodinfo.prevAll('.row').find('.js-card-product-info-header')
                        .find('.js-shipping-fulfillment-method')
                        .removeClass('d-none');
                        $cardprodinfo.prevAll('.row').find('.js-card-product-info-header')
                        .find('.js-order-pick-up-fulfillment-method')
                        .addClass('d-none');
                    }
                }
                window.location.reload();
            },
            error: function error(err) {
            }
        });
    });
};
