
var $ = window.jQuery;

exports.preprocessImpression = function (impression, element) {
    var position = $(element).closest('[data-gtm-current-position]').attr('data-gtm-current-position');
    var term;

    var dataElement = window.dataLayer.find(function (item) {
        return item.event === 'view_search_results';
    });

    if (position) {
        impression.index = parseInt(position, 10);
    }

    if (dataElement && dataElement.search_term) {
        term = JSON.parse('{"' + decodeURI(dataElement.search_term).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

        var list = term.cgid;
        impression.item_list_id = list;
        impression.item_list_name = list.replace(/-/g, ' ').replace(/(^|\s)\S/g, function (name) {
            return name.toUpperCase();
        });
    }

    return impression;
};
