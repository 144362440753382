'use strict';

var base = module.superModule || require('base/components/footer');
var scrollAnimate = require('./scrollAnimate');

var _ = require('lodash');

/**
 * Shows or hides the "back to top" button based on the current scroll position
 *
 * @param {jQuery} scroller - The object/window being scrolled
 */
function displayBackToTop(scroller) {
    const $scroller = $(scroller);
    const $backToTop = $('.js-back-to-top');
    const scrollTop = $scroller.scrollTop();
    const windowHeight = window.innerHeight;
    const windowHeightScaled = windowHeight / 1.5; // Scale it to the point we want to display the button

    if (scrollTop > windowHeightScaled && $backToTop.hasClass('d-none')) {
        $backToTop.removeClass('d-none');
    } else if (scrollTop <= windowHeightScaled && !$backToTop.hasClass('d-none')) {
        $backToTop.addClass('d-none');
    }
}

const displayBackToTopThrottled = _.throttle(function (scroller, baseModule) {
    let displayBackToTopScoped = displayBackToTop;
    if (baseModule && baseModule.methods && baseModule.methods.displayBackToTop) {
        displayBackToTopScoped = baseModule.methods.displayBackToTop;
    }

    displayBackToTopScoped(scroller);
}, 350);


/**
 * On scroll, call a throttled version of displayBackToTop
 */
function displayBackToTopOnScroll() {
    const baseModule = this;
    let displayBackToTopThrottledScoped = displayBackToTopThrottled;

    if (baseModule && baseModule.methods && baseModule.methods.displayBackToTopThrottled) {
        displayBackToTopThrottledScoped = baseModule.methods.displayBackToTopThrottled;
    }

    const $scroller = $(window);
    $scroller.add('body'); // Cover all potential cases

    $scroller.on('scroll', function () {
        displayBackToTopThrottledScoped(this, baseModule);
    });
}

/** Base version of back to top function
 */

function backToTop() {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });
}

base = typeof base === 'function' ? {} : base;

base.methods = {
    displayBackToTop: displayBackToTop,
    displayBackToTopThrottled: displayBackToTopThrottled
};

base.backToTop = backToTop;
base.displayBackToTop = displayBackToTopOnScroll;

module.exports = base;
