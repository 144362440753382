'use strict';

var $ = require('jquery');

/**
 * Toggles namespaced 'active' class on html element.
 * 'href' value is used for the namespace. If target
 * is not an 'a[href]' the click will not be bound.
 *
 */

const $html = $('html');

const aToggle = {
    activeClasses: [],
    activeNS: [],
    activeTargets: [],
    clearAll() {
        // Clear all active aToggle classes.
        // Only one should be active at a time.
        aToggle.activeClasses.forEach(ac => {
            $html.removeClass(ac);
        });
        // Toggle any aria attrs
        aToggle.activeTargets.forEach(target => {
            $(target).attr('aria-expanded', 'false');
        });
        aToggle.activeClasses = [];
        aToggle.activeNS = [];
        aToggle.activeTargets = [];
    },
    init() {
        $('body')
        .on('click', '.js-a-toggle', e => {
            e.preventDefault();
            e.stopPropagation();

            const $tgt = $(e.currentTarget);
            var ns;

            if ($tgt.attr('href')) {
                ns = $tgt.attr('href').replace(/[^a-z-]/ig, '');
            } else if ($tgt.attr('data-href')) {
                ns = $tgt.attr('data-href').replace(/[^a-z-]/ig, '');
            } else {
                return;
            }

            const activeClass = `is-${ns}-active`;
            const isActive = $html.is(`.${activeClass}`);

            aToggle.clearAll();

            if (!isActive) {
                $html.addClass(activeClass);
                $($tgt).attr('aria-expanded', 'true');
                // Log class to be cleared later by another toggle if necessary.
                aToggle.activeClasses.push(activeClass);
                aToggle.activeNS.push(ns);
                aToggle.activeTargets.push($tgt);
            }

            // Focus to element with ID if set in "data-js-a-toggle-focus"
            // of target link.
            const focusID = $tgt.data('js-a-toggle-focus');
            const $focusEl = $(`#${focusID}`);
            if ($focusEl.length) {
                $focusEl.click().focus();
            }

            if (isActive) {
            // Add ARIA attribute
                $($tgt).attr('aria-expanded', 'false');
            }

            // Animate.
            if ($tgt.hasClass('.js-a-toggle--animate')) {
                const $animateTgt = $($tgt.attr('href'));
                if ($animateTgt.length) {
                    $animateTgt.slideToggle();
                }
            }
        })
        .on('click', '[data-js-a-toggle-close]', e => {
            e.preventDefault();
            aToggle.clearAll();
        })
        .on('click', e => {
            var doClear = true;
            for (let i = 0; i < aToggle.activeNS.length; i++) {
                let ns = aToggle.activeNS[i];
                if ($(e.target).closest(`#${ns}`).length) {
                    doClear = false;
                    break;
                }
            }

            if (doClear) {
                aToggle.clearAll();
            }
        });
    }
};

module.exports = aToggle;
