window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/pdp-carousel'));
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('./components/stickyheader'));
    processInclude(require('./product/swatchableAttribute'));
    processInclude(require('./cart/cart'));
    processInclude(require('./components/emailSubscription'));
    processInclude(require('./components/image-carousel'));
    processInclude(require('./content/navigation'));
    processInclude(require('./components/header-banner'));
    processInclude(require('./components/toggle'));
    processInclude(require('./components/country-selector'));
    processInclude(require('./linkModal'));
    processInclude(require('./components/refinement-tooltips'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/modal'));
    processInclude(require('./components/modalDrawer'));
    processInclude(require('./components/form-helpers'));
    processInclude(require('./components/tabs'));
    processInclude(require('./components/footer'));
    processInclude(require('./silverpop'));
    processInclude(require('./components/flyout-panel'));
    processInclude(require('./helpers/video-media-query'));
    processInclude(require('./components/legacy-component-support'));
    processInclude(require('./forms/front-end-validation'));
    processInclude(require('./components/volumental'));

    // Modules who export an object whose every function we don't want to necessarily loop through via processInclude
    var aToggle = require('./util/toggleActiveClass');

    aToggle.init();

    var GTM = require('gtm/gtm/init');
    GTM.init();

    try {
        require('./liveagent/liveagent');
    } catch (e) {} //eslint-disable-line
});

require('base/thirdParty/bootstrap');
require('./components/spinner');
require('jquery-ui/ui/widgets/tabs');
require('@accessible360/accessible-slick');
require('jquery-zoom');
require('svgxuse');
