'use strict';

var base = require('base/components/clientSideValidation');

/**
 * @param {string} resp - The reCaptcha response data that will be passed to the form
 */
function reCaptchaValidated(resp) {
    if (resp) {
        $('#g-recaptcha-response').removeClass('is-invalid');
    }
}

// Need to export to window so as to be accessible to the callback
window.reCaptchaValidated = reCaptchaValidated;

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (this.checkValidity) {
        // safari
        valid = this.checkValidity();
        if (!valid) {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            }

            $(this).find('input, select, textarea').each(function () {
                $(this).trigger('invalid', this.validity);
            });
        } else {
            $(this).find('input, select, textarea').removeClass('is-invalid');
        }
    }

    return valid;
}

module.exports = $.extend(true, {}, base, {
    // Add form textareas to invalid functionality
    invalid: function () {
        $('form input, form select, form textarea').on('invalid', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var validationMessage;
                $(this).addClass('is-invalid');
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }

                // IE 11 doesn't have property validity.tooShort, causing a different error message to show when
                // the input has a value that doesn't meet the minimum length. To not change any functionality,
                // new `data-default-error` attribute is used as a default error message if available.
                if (!validationMessage) {
                    validationMessage = $(this).data('default-error')
                        ? $(this).data('default-error')
                        : this.validationMessage;
                }
                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
            } else {
                // Add handling for a valid form
                $(this).removeClass('is-invalid');
            }
        });
    },

    submit: function () {
        $('form').on('submit', function (e) {
            return validateForm.call(this, e);
        });
    },

    functions: {
        /**
         * Calls validate form on a given form
         *
         * Updated from base to return the validity,
         * and to not render itself pointless by making form into a jQuery object
         *
         * @param {HTML} form - HTML form object
         * @param {Event} event - JS event
         * @returns {boolean} The validity of the form
         */
        validateForm: function (form, event) {
            return validateForm.call(form, event);
        },
        clearForm: base.functions.clearForm
    }
});
