var keyboardAccessibility = require('base/components/keyboardAccessibility');
const clientSideValidation = require('app_rws/components/clientSideValidation');


/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length
        ? $($el).closest('.product-detail').find('.quantity-select')
        : $('.quantity-select');
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

function setTabIndex() {
    // Set tab index on first focusable variation option, if variation in group is not already selected
    var variationAttribute = $('.js-product-attribute-item');
    $(variationAttribute).each(function () {
        var $this = this;
        var hasSelectedItem = $($this).find('.selected').length > 0;
        if (!(hasSelectedItem)) {
            $($this).find('.selectable:not(.selected)').first().attr('tabindex', '0');
        }
    });
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processSwatchValues(attr, $productContainer) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        var $swatchAnchor = $attrValue.parent().attr('data-href') ? $attrValue.parent() : $attrValue;

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.attr('aria-checked', 'true');
            $attrValue.attr('tabindex', '0');
        } else {
            $attrValue.removeClass('selected');
            $attrValue.attr('aria-checked', 'false');
            $attrValue.attr('tabindex', '-1');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable d-none');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');

        $attrValue.addClass(attrValue.invalid ? 'd-none' : '');

        // Handle aria-disabled attribute
        if (attrValue.selectable) {
            $attrValue.attr('aria-disabled', 'false');
        } else {
            $attrValue.attr('aria-disabled', 'true');
        }

        if (attrValue.url) {
            $swatchAnchor.attr('data-href', attrValue.url);
        } else {
            $swatchAnchor.removeAttr('data-href');
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAttrs(attrs, $productContainer) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithoutSwatches = [];

    attrs.forEach(function (attr) {
        if (attrsWithoutSwatches.indexOf(attr.id) > -1) {
            processNonSwatchValues(attr, $productContainer);
        } else {
            processSwatchValues(attr, $productContainer);
        }
    });

    setTabIndex();
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<div>' + response.resources.info_selectforstock + '</div>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<div>' + message + '</div>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} options - Ajax response options from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(options, $productContainer) {
    options.forEach(function (option) {
        var $optionEl = $productContainer.find('.product-option[data-option-id*="' + option.id
            + '"]');
        option.values.forEach(function (value) {
            var valueEl = $optionEl.find('option[data-value-id*="' + value.id + '"]');
            valueEl.val(value.url);
        });
    });
}

/**
 * Generates html for promotions section
 *
 * @param {array} promotions - list of promotions
 * @param {Object} resources - relevant resource file entries
 * @return {string} - Compiled HTML
 */
function getPromotionsHtml(promotions, resources) {
    if (!promotions) {
        return '';
    }

    var html = '';

    promotions.forEach(function (promotion) {
        html += '<div class="c-product-promo callout">' + promotion.calloutMsg;
        if (typeof promotion.details !== 'undefined' && promotion.details !== null && promotion.details.length > 0) {
            html += '<a '
                        + 'class="c-product-promo__details-link js-toggle-modal" '
                        + 'href="#" '
                        + 'data-modal-header="'
                        + '<h1 class=\'modal-title c-product-promo__modal-header\'>' + resources.link_promoDetailsHeader + '</h1>">'
                        + resources.link_promoDetails
                        + '<div class="c-product-promo__details-wrapper js-toggle-content">'
                            + '<div class="c-product-promo__details">'
                                + promotion.details
                            + '</div>'
                        + '</div>'
                    + '</a>';
        }
        html += '</div>';
    });

    return html;
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImageUrls = response.product.images;
    primaryImageUrls.large.forEach(function (imageUrl, idx) {
        $productContainer.find('.primary-images').find('img').eq(idx)
            .attr('src', imageUrl.url);
    });

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $('.promotions').empty().html(getPromotionsHtml(response.product.promotions, response.resources));

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));
}

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if (!($productContainer.parent('.bonus-product-item').length > 0)) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.options, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);

                $('.js-sourcing-locations').html('').html(data.fulfillmentMethodHtml);

                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                var $attributeContainers = $productContainer.find('.js-product-attribute-item');
                var errorContainerSelector = '.js-add-to-cart-validation';
                var $errorContainer;
                $attributeContainers.each(function () {
                    $errorContainer = $(this).find(errorContainerSelector);
                    $errorContainer.addClass('d-none');
                });
                if ('customization' in data.product && data.product.customization && $('.js-product-customization-load').length > 0) {
                    $('.js-product-customization-load').html(data.product.customization);
                    $('.js-swap-company').trigger('change');
                }

                $('.js-required-field').on('blur', function (e) {
                    var $this = this;
                    var $form = $($this).parents('.js-validate-form');
                    if ($(this).val()) {
                        $(this).val().trim();
                        e.preventDefault();
                        var isFormValid = clientSideValidation.functions.validateForm($form[0], e);
                        if (isFormValid) {
                            $(this).addClass('selected');
                        } else {
                            $(this).removeClass('selected');
                        }
                    } else {
                        $(this).removeClass('selected');
                    }
                });

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

module.exports = function () {
    $(document).on('click', '[data-attr] button[data-attr-value]', function (e) {
        e.preventDefault();

        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }

        var url = $(e.currentTarget).attr('data-href');
        var fulfillmentMethod = $("input[type='radio'][name=fulfillmentMethod]:checked");
        if (fulfillmentMethod.length > 0) {
            var valueId = fulfillmentMethod.data('value-id');
            url += `&options=[{"optionId":"sourcingLocation","selectedValueId":"${valueId}"}]`;
        }

        attributeSelect(url, $productContainer);
    });

    $('body').on('change', '.js-fulfillment-method', function (e) {
        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }

        attributeSelect($(e.currentTarget).attr('data-action-url'), $productContainer);
    });

    keyboardAccessibility('.js-attribute-value',
        {
            39: function () { // right
                if ($(this).is(':focus')) {
                    $(':focus').nextAll('.selectable:first').trigger('click').trigger('focus');
                }
            },
            37: function () { // left
                if ($(this).is(':focus')) {
                    $(':focus').prevAll('.selectable:first').trigger('click').trigger('focus');
                }
            },
            40: function () { // down
                if ($(this).is(':focus')) {
                    $(':focus').nextAll('.selectable:first').trigger('click').trigger('focus');
                }
            },
            38: function () { // up
                if ($(this).is(':focus')) {
                    $(':focus').prevAll('.selectable:first').trigger('click').trigger('focus');
                }
            }
        },
        function () {
            return $(this).parent();
        }
    );

    setTabIndex();
};
