'use strict';

module.exports = {
    init: function () {
        // If the featured banner does not use a video, add a class to manage the image background size and other styles
        var videoId = $('[data-featured-banner-video-id]').attr('data-featured-banner-video-id');

        if (!videoId) {
            $('.js-featured-banner').addClass('video-disabled');
            return;
        }
    }
};
