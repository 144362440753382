'use strict';

module.exports = {
    init: function () {
        $('.js-country-selector').on('change', function () {
            var url = $(this).val();
            if (url) {
                window.location = url;
            }
            return false;
        });
    }
};
