'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var $pdpCarousel = $('.js-pdp-carousel');
var $pdpCarouselThumbs = $('.js-pdp-carousel-thumb');
var $pdpZoomCarousel = $('.js-zoom-carousel');

function processThumbnailActiveState($thumbnail, thumbnailIndex) {
    if ($pdpZoomCarousel.length && $pdpZoomCarousel.hasClass('slick-initialized')) {
        $pdpZoomCarousel.slick("slickGoTo", thumbnailIndex);

        // Toggle aria-current state
        $('.js-zoom-thumbnails').find('.js-slide-indicator.active').attr('aria-current', false);
        $($thumbnail).attr('aria-current', 'true');

        // Toggle active state
        $('.js-zoom-thumbnails').find('.js-slide-indicator').removeClass('active');
        $($thumbnail).addClass('active');
    } else {
        $('.js-pdp-carousel').slick("slickGoTo", thumbnailIndex);

        // Toggle aria-current state
        $('.js-pdp-carousel-thumb').find('.js-slide-indicator.active').attr('aria-current', false);
        $($thumbnail).attr('aria-current', 'true');

        // Toggle active state
        $('.js-pdp-carousel-thumb').find('.js-slide-indicator').removeClass('active');
        $($thumbnail).addClass('active');
    }
}

function detectActiveVideoSlide($currentSlideElement, $slideContainer) {
    // detect when video slide is active to hide zoom icon, any badge or logo on the carousel
    // play the video

    if ($($currentSlideElement).find('.js-carousel-video').length > 0) {
        $($slideContainer).addClass('st-product-detail__image-carousel--video-active');
        // Make sure the video tag and transcript buttons are focusable when the slide is active.  Assumes only 1 video
        $('.js-carousel-video video, .js-video-transcript-button').removeAttr('tabindex');
        if ($(window).width() > 1024 && $('.js-video-file').length > 0) {
            var video = '#' + $($currentSlideElement).find('.js-video-file').attr('data-id');
            $(video)[0].play();
        }
    } else {
        $($slideContainer).removeClass('st-product-detail__image-carousel--video-active');
        // Make sure the video tag and transcript buttons are not focusable when the slide is not active
        $('.js-carousel-video video, .js-video-transcript-button').attr('tabindex', '-1');
    }
}

module.exports = {
    init: function () {
        // Add aria-current="true" to active thumbnail - ADA 124 and handle active state
        $(document).on('click', '.js-slide-indicator', function (e) {
            var $thumbnail = this;
            var thumbnailIndex = $($thumbnail).data('slide-to');
            processThumbnailActiveState($thumbnail, thumbnailIndex);
        });

        $($pdpCarousel).on('init', function (e, slick) {
            var $currentSlideElement = $(slick.$slides.find('.slick-current'));
            var $slideContainer = $('.js-product-detail-image-carousel');
            detectActiveVideoSlide($currentSlideElement, $slideContainer);
        });

        // If the thumbnail slider has less than 6 slides, we need to treat this as if it's not
        // a carousel.  So, we have to modify a bunch of aria attributes.  ADA-106
        $($pdpCarouselThumbs).on('init', function () {
            if (!$($pdpCarouselThumbs).hasClass('js-pdp-carousel-thumb--more-than-six')) {
                $pdpCarouselThumbs.removeAttr('role').removeAttr('aria-label');
                $($pdpCarouselThumbs).find('.slick-instructions').attr('aria-hidden', true);
                $($pdpCarouselThumbs).find('.slick-slide').attr('role', 'listitem');
                $($pdpCarouselThumbs).find('.slick-track').attr('role', 'list');
            }
        });

        $('.js-pdp-carousel:not(.slick-initialized)').slick();

        $('.js-pdp-carousel-thumb:not(.slick-initialized)').slick();

        $('.js-carousel-item').removeClass('d-none');

        $('.js-pdp-carousel-thumb').removeClass('d-none');

        $($pdpCarousel).on('afterChange', function (e, slick, currentSlide) {
            var $currentSlideElement = $(slick.$slides.get(currentSlide));
            var $slideContainer = $('.js-product-detail-image-carousel');
            var $thumbnail = $('.c-image-carousel__thumbnails__item .js-slide-indicator').eq(currentSlide);
            var thumbnailIndex = $($thumbnail).data('slide-to');

            processThumbnailActiveState($thumbnail, thumbnailIndex);
            detectActiveVideoSlide($currentSlideElement, $slideContainer);
        });

        keyboardAccessibility('.js-slide-indicator',
            {
                13: function ($thumbnail) { // enter
                    $thumbnail = $thumbnail.find('.js-slide-indicator');
                    $thumbnail.trigger('click');
                    processThumbnailActiveState($thumbnail);
                }
            },
            function () {
                return $(this).parent();
            }
        );
    }
};
