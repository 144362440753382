'use strict';

var cookie = require('app_rws/components/cookie');

/**
 * Sets the UI based on cookie state
 */

function toggleButtonUI() {
    let val = cookie.getCookieValue('isOutside');
    if (val === '' || val === 'false') {
        // Not logged outside
        $(document).find('.js-log-outside-button--outside').removeClass('show fade-in');
        $(document).find('.js-log-outside-button--outside').addClass('d-none');
        $(document).find('.js-log-outside-button--inside').removeClass('d-none');
        $(document).find('.js-log-outside-do-more-buttons').removeClass('d-none');
        $(document).find('.js-log-outside-do-more-buttons').addClass('d-flex');
    } else {
        // Logged outside
        $(document).find('.js-log-outside-button--outside').addClass('show fade-in');
        $(document).find('.js-log-outside-button--outside').removeClass('d-none');
        $(document).find('.js-log-outside-button--inside').addClass('d-none');
        $(document).find('.js-log-outside-do-more-buttons').addClass('d-none');
        $(document).find('.js-log-outside-do-more-buttons').removeClass('d-flex');
    }
}

/**
 * Toggles the cookie state
 */

function toggleCookie() {
    let val = cookie.getCookieValue('isOutside');
    if (val === '' || val === 'false') {
        document.cookie = 'isOutside=true; path=/';
    } else {
        document.cookie = 'isOutside=false; path=/';
    }
}

function triggerPanel() {
    const button = $(document).find('.js-open-flyout');
    if (button.length) {
        button.trigger("click");
    }
}

module.exports = {
    init: function () {
        if (window.location.search.length) {
            const urlParams = new URLSearchParams(window.location.search);
            const logOutside = urlParams.get('LogOutside');

            if (logOutside && logOutside === "show") {
                triggerPanel();
            }
        }

        if ($('.js-flyout-panel').length) {
            toggleButtonUI();
        }

        // When clicking on the learn more button, show the content and hide the initial CTA buttons
        $(document).on('click', '.js-learn-more', function (e) {
            e.preventDefault();
            $(document).find('.js-log-outside-actions').addClass('d-none');
            $(document).find('.js-log-outside-text').removeClass('d-none');
            $(document).find('.js-log-outside-text').addClass('show fade-in');
        });

        // When clicking on the back button, hide the content and show the initial CTA buttons
        $(document).on('click', '.js-log-outside--back-button', function (e) {
            e.preventDefault();
            $(document).find('.js-log-outside-actions').removeClass('d-none');
            $(document).find('.js-log-outside-text').removeClass('show fade-in');
            $(document).find('.js-log-outside-text').addClass('d-none');
            $(document).find('.js-learn-more').trigger('focus');
        });

        // Read cookie state and set UI based on cookie state
        $(document).on('click', '.js-log-outside-button', function (e) {
            e.preventDefault();
            toggleCookie();
            toggleButtonUI();
        });

        // Scroll to the full length video when user clicks on View Full Video
        $(document).on('click', '.js-log-outside-watch-video-button', function (e) {
            e.preventDefault();
            const videoContainer = document.getElementsByClassName('js-log-outside-full-video-container');
            $(videoContainer)[0].scrollIntoView({
                behavior: 'smooth',
                block: 'end'
            });

            $('.js-log-outside-full-video').trigger('focus');
        });

        // Pause video when closing flyout
        $('.js-log-outside-flyout-modal').on('hidden.bs.modal', function () {
            $('.js-log-outside-full-video').trigger('pause');
        });
    }
};
