'use strict';

/**
 * A set of form helpers for user interaction and accessibility
 */

module.exports = {
    init: function () {
        // Set form focus to first field with error
        $('input').on('invalid', function (e) {
            $(this).parents('form').find('.is-invalid')[0].focus();
        });
    }
};
