'use strict';

/**
 * Auto-selects the size based on Volumental Recommendation
 * @param {string} recommendedSize string containing the volumental size.
 * @returns {Object} returns matching button if found
 */
function findSizeButton(recommendedSize) {
    var sizeButtons = $('.js-product-attribute-values').find('button.size-value');
    for (var i = 0; i < sizeButtons.length; i++) {
        var button = $(sizeButtons[i]);
        var sizeVal = button.data('attr-value').toString();
        if (sizeVal === recommendedSize) {
            return button;
        }
    }
    return null;
}

/**
 * Auto-selects the width based on Volumental Recommendation
 * @param {string} recommendedWidth string containing the volumental width.
 * @returns {Object} returns matching button if found
 */
function findWidthButton(recommendedWidth) {
    var widthButtons = $('.js-product-attribute-values').find('button.width-value');
    for (var i = 0; i < widthButtons.length; i++) {
        var button = $(widthButtons[i]);
        var widthVal = button.data('attr-value').toString();
        if (widthVal === recommendedWidth) {
            return button;
        }
    }
    return null;
}

/**
 * Auto-selects the width based on Volumental Recommendation
 * @param {string} detail volumental object containing size and width.
 */
function selectSizeAndWidth(detail) {
    var recommendedSize = detail.length.replace('.', '');
    var recommendedWidth = detail.width.replace('1', '');
    var recommendedSelected = false;
    recommendedSize = recommendedSize.length === 2 ? recommendedSize + '0' : recommendedSize;

    const sizeButton = findSizeButton(recommendedSize);
    const widthButton = findWidthButton(recommendedWidth);
    if (sizeButton && widthButton) {
        if (!sizeButton.hasClass('selected')) {
            sizeButton.trigger('click');
        }
        $('body').on('product:afterAttributeSelect', (e) => {
            if (!recommendedSelected && !widthButton.hasClass('selected')) {
                widthButton.trigger('click');
                recommendedSelected = true;
            }
        });
    }
}

$(document).ready(function () {
    const pid = $('.js-volumental-pid').val();
    $('#volumental-recommendation').attr('product-id', pid);

    $('#volumental-recommendation').on('volumental:on-opened', (e) => {
        // Logic when modal opens here.
    });

    $('#volumental-recommendation').on('volumental:on-recommendation', (e) => {
        selectSizeAndWidth(e.detail);
    });

    $('#volumental-recommendation').on('volumental:on-closed', (e) => {
        // Logic when modal closes here.
    });
});
