
function getModalHtmlElement() {
    if ($('#jsModalDialog').length !== 0) {
        $('#jsModalDialog').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="jsModalDialog" role="dialog">'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">'
        + '        &times;'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}
module.exports = {
    modalLinks: function () {
        $(document).on('click', '.js-modal-dialog', function (ev) {
            ev.preventDefault();
            var href = $(this).attr('href');
            getModalHtmlElement();
            var $dialog = $('#jsModalDialog');
            $.ajax({
                url: href + '?format=ajax',
                type: 'get',
                success: function (data) {
                    $dialog.find('.modal-body').html(data);
                    $dialog.modal('show');
                }
            });
        });
    }
};
