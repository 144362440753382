/**
 * @function
 * @description Checks if current screen size breakpoint matches with the needed
 * @param {string} breakpoints size name to check
 * @returns {boolean} breakpoints match result
 */
module.exports = function (breakpoints) {
    var screenSize = '';
    var matches = false;

    if (window.getComputedStyle) {
        // get screen size as defined in _grid-settings.scss
        screenSize = getComputedStyle(document.querySelector('body'), ':after').content.replace(/[^a-z-]/ig, '');
        matches = (breakpoints.indexOf(screenSize) >= 0 || !screenSize);
    }

    return matches;
};
