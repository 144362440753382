'use strict';

/**
 * stickyheader
 */

// Initial loading of stickyheader

var matchBreakpoints = require('../util/matchBreakpoints');
var $header = $('.js-global-header');
var $headerTopBar = $('.js-top-bar');
var $globalHeaderWrapper = $('.js-global-header-wrapper');
var $searchBlock = $('.js-search-container');
var $main = $('#maincontent');
var stickyClass = 'is-sticky';
var headerTopbarHeight = ($headerTopBar).outerHeight();
var searchBlockHeight = ($searchBlock).outerHeight();

var stickyheader = {

    init: function () {
        var lastScrollTop = 0;
        var isLastScrollDown = false;
        var isSticky = false;

        $(window).on('scroll', function (ev) {
            var scrollTop = $(window).scrollTop();
            var isScrollDown = scrollTop > lastScrollTop;

            var $globalHeaderWrapperHeight = $('.js-global-header-wrapper').outerHeight();
            var headerBlockHeight = headerTopbarHeight + searchBlockHeight;

            if (scrollTop > (headerBlockHeight + $globalHeaderWrapperHeight) && !isSticky) {
                ($header).addClass(stickyClass);
                $globalHeaderWrapper.css({ top: -1 * $globalHeaderWrapperHeight });
                $main.css('margin-top', $globalHeaderWrapperHeight);
                isSticky = true;

                if (!matchBreakpoints(['lg-up'])) {
                    $globalHeaderWrapperHeight = $('.js-global-header-wrapper').outerHeight();
                    $globalHeaderWrapper.css({ top: -1 * $globalHeaderWrapperHeight });
                    $main.css('margin-top', $globalHeaderWrapperHeight);
                }
            } else if (scrollTop <= headerBlockHeight && isSticky) {
                ($header).removeClass(stickyClass);
                $globalHeaderWrapper.css({ top: 0 });
                $main.css('margin-top', 0);
                isSticky = false;
            }

            if (scrollTop > headerBlockHeight) {
                if (isLastScrollDown && !isScrollDown) {
                    $globalHeaderWrapper.stop().animate({ top: 0 });
                } else if (!isLastScrollDown && isScrollDown) {
                    $globalHeaderWrapper.stop().animate({ top: -1 * $globalHeaderWrapperHeight });
                }
            }

            lastScrollTop = scrollTop;
            isLastScrollDown = isScrollDown;
        });
    }

};

module.exports = stickyheader;
