var MODAL_FOCUSABLE_ELEMENTS = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
var keyboardAccessibility = require('base/components/keyboardAccessibility');

module.exports = {
    captureModalFocus: function () {
        // Keep focus within modals when tabbing
        $('body').on('shown.bs.modal', function (modalEvent) {
            var $modal = $(modalEvent.target);
            var $focusable = $modal.find(MODAL_FOCUSABLE_ELEMENTS).not('.d-none');
            var $first = $focusable.first();
            var $last = $focusable.last();

            $first.focus();

            var tabHandler = function (tabEvent) {
                if (tabEvent.key !== 'Tab' && tabEvent.keyCode !== 9) {
                    return;
                } else if ($focusable.length === 1) {
                    // When only one element in the modal is focusable, prevent tab from moving focus
                    $focusable.focus();
                    tabEvent.preventDefault();
                    return;
                }

                // If shift key is pressed, user is tabbing backwards
                var result = tabEvent.shiftKey
                    ? { compareTo: $first[0], goTo: $last[0] }
                    : { compareTo: $last[0], goTo: $first[0] };

                if (document.activeElement === result.compareTo) {
                    result.goTo.focus();
                    tabEvent.preventDefault();
                }
            };

            // Remove handler after the modal is hidden
            $('body').one('hidden.bs.modal', modalEvent.target, function () {
                $(document).off('keydown', tabHandler);
            });

            $(document).on('keydown', tabHandler);

            keyboardAccessibility('.js-attribute-value',
                {
                    39: function () { // right
                        if ($(this).is(':focus')) {
                            $(':focus').nextAll('.selectable:first').trigger('click').trigger('focus');
                        }
                    },
                    37: function () { // left
                        if ($(this).is(':focus')) {
                            $(':focus').prevAll('.selectable:first').trigger('click').trigger('focus');
                        }
                    },
                    40: function () { // down
                        if ($(this).is(':focus')) {
                            $(':focus').nextAll('.selectable:first').trigger('click').trigger('focus');
                        }
                    },
                    38: function () { // up
                        if ($(this).is(':focus')) {
                            $(':focus').prevAll('.selectable:first').trigger('click').trigger('focus');
                        }
                    }
                },
                function () {
                    return $(this).parent();
                }
            );
        });
    }
};
