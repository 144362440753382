'use strict';

var activeClass = 'is-active';
var inactiveClass = 'is-not-active';
var largeScreenWidth = 1025;
var $initializedTabs;

var keyboardAccessibility = require('base/components/keyboardAccessibility');

var initMenuTabs = function () {
    var $menuTabs = $('.js-menu-tabs');
    if ($(window).outerWidth() >= largeScreenWidth) {
        if ($initializedTabs) {
            $menuTabs.tabs('destroy');
            $('.js-product-accordion__body').attr('style', '');
            $initializedTabs = null;
        }
    } else {
        $initializedTabs = $initializedTabs || $menuTabs.tabs();
    }
    // Remove any mobile-only attributes on desktop
    $('.c-navigation-global__item').removeAttr('aria-expanded');
};

var allowMobileKeyboardSupport = function () {
    if ($(window).width() < largeScreenWidth) {
        $('.c-navigation-global__link--level-2').attr('role', 'button').attr('tabindex', '0');
        $('.c-navigation-global__link--level-1').attr('role', 'button').attr('tabindex', '0');
        // Remove any aria-expanded attributes from Level 2 tabs (Setter ADA #74)
        $('.c-megamenu__sublevel-navigation__tab').removeAttr('aria-expanded');
    } else {
        $('.c-navigation-global__link--level-2').removeAttr('role').removeAttr('tabindex');
        $('.c-navigation-global__link--level-1').removeAttr('tabindex');
    }
};

var selectMenuItem = function ($menuItem) {
    $menuItem.addClass(activeClass)
        .siblings().addClass(inactiveClass);
};

var deselectMenuItem = function ($menuItem) {
    $menuItem.removeClass(activeClass)
        .siblings().removeClass(inactiveClass);
};

var toggleMenuItemSelection = function ($menuItem) {
    if ($menuItem.hasClass(activeClass)) {
        deselectMenuItem($menuItem);
    } else {
        selectMenuItem($menuItem);
    }
};

var processAriaAttributes = function ($this, $menuItem) {
    if ($($this).find('.c-megamenu').is(":visible") && $($this).hasClass('has-subnav')) {
        $($this).find('.d-lg-none .c-navigation-global__link--level-1').attr('aria-expanded', 'true');
    } else {
        $($this).find('.d-lg-none .c-navigation-global__link--level-1').attr('aria-expanded', 'false');
    }
};

module.exports = function () {
    initMenuTabs();

    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('hide');
    });

    $('.c-megamenu__sublevel-close-button').on('click', function () {
        var menuItemParent = $(this).parents('.nav-item');
        $(menuItemParent).removeClass('show');
        $(menuItemParent).find('.nav-link').attr('aria-expanded', 'false');
        $(menuItemParent).children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
    });

    keyboardAccessibility('.main-menu .dropdown-link, .js-nav-item, .js-nav-link',
        {
            40: function ($menuItem) {
                // down
                if ($menuItem.hasClass('nav-item')) { // top level
                    $menuItem.find('.nav-link').attr('aria-expanded', 'true');
                    $menuItem.addClass('show').children('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
                    var firstLink = $($menuItem).find('.js-nav-link').first();
                    $(firstLink).focus();
                } else if ($(this).hasClass('title')) {
                    $menuItem.children('.content').find('li a').first().focus();
                } else if ($(this).is(':last-of-type')) {
                    ($menuItem).parents('.c-megamenu__tab-content').next('div').find('a').first()
                        .focus();
                } else if ($(this).hasClass('js-nav-link')) {
                    $(this).next('li').find('a').focus();
                } else {
                    ($menuItem).next('li').find('a').focus();
                    $(this).siblings('a').focus();
                }
            },
            39: function ($menuItem) { // right
                if ($menuItem.hasClass('nav-item')) { // top level
                    $menuItem.removeClass('show').children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
                    $(this).attr('aria-expanded', 'false');
                    $menuItem.next().children().first().focus();
                }
            },
            38: function ($menuItem) { // up
                if ($menuItem.hasClass('nav-item')) { // top level
                    $menuItem.find('.nav-link').attr('aria-expanded', 'true');
                    $menuItem.addClass('show').children('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
                } else if ($(this).is(':first-of-type')) {
                    $menuItem.parents('.content').prev('.title').focus();
                } else {
                    $(this).prev('li').find('a').focus();
                }
            },
            37: function ($menuItem) { // left
                if ($menuItem.hasClass('nav-item')) { // top level
                    $menuItem.removeClass('show').children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
                    $(this).attr('aria-expanded', 'false');
                    $menuItem.prev().children().first().focus();
                }
            },
            27: function ($menuItem) { // escape
                if (!($menuItem.hasClass('nav-item'))) { // menu item
                    var menuItemParent = $menuItem.parents('.nav-item');
                    $(menuItemParent).removeClass('show');
                    $(menuItemParent).find('.nav-link').attr('aria-expanded', 'false');
                    $(menuItemParent).children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
                }
            },
            13: function ($menuItem) { // enter
                if ($(this).hasClass('toggle-button')) { // top level
                    $menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    $menuItem.next().children().first().focus();
                } else {
                    var $this = this;
                    $(this).find('.d-lg-block button').trigger('click');
                    processAriaAttributes($this, $menuItem);
                }
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.toggle-button').on('click', function (e) {
        $(this).parent().next('.dropdown-menu').toggleClass('show');
        if ($(this).parent().next('.dropdown-menu').hasClass('show')) {
            $(this).attr('aria-expanded', 'true').parent().next('.dropdown-menu')
                .attr('aria-hidden', 'false');
            var firstLink = $(this).parent().next('.dropdown-menu').find('a')
                .first();
            firstLink.focus();
        } else {
            $(this).attr('aria-expanded', 'false').parent().next('.dropdown-menu')
                .attr('aria-hidden', 'true');
            $(this).focus();
        }
    });

    $('.nav-link').on('mouseenter', function (e) {
        $(this).next('.dropdown-menu').attr('aria-hidden', 'false');
    });

    $('.nav-link').on('mouseout', function (e) {
        $(this).next('.dropdown-menu').attr('aria-hidden', 'true');
    });

    $('.navbar-nav').on('click', '.nav-item.has-subnav', function (e) {
        if (!$(e.target).closest('.dropdown-menu').length && $(window).outerWidth() < largeScreenWidth) {
            e.preventDefault();
            toggleMenuItemSelection($(this));
        }
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        $('html').toggleClass('is-menu-active');
        $('.main-menu').toggleClass('in');
        $('.modal-background').toggle();
        if ($('html').hasClass('is-menu-active')) {
            $(this).attr('aria-expanded', 'true');
        } else {
            $(this).attr('aria-expanded', 'false');
        }
    });

    $(window).on('resize', function () {
        initMenuTabs();
        allowMobileKeyboardSupport();
        $('html').removeClass('is-menu-active');
        $('.main-menu').removeClass('in');
        $('.modal-background').hide();
    });
    if ($(window).width() < largeScreenWidth) {
        allowMobileKeyboardSupport();
        $(document).on('click', '.c-navigation-global__item', function () {
            var $this = this;
            processAriaAttributes($this);
        });
    }
    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                var $focusedItem = $popover.children('a').is(':focus');
                if (!($focusedItem)) {
                    $popover.children('a').first().focus().addClass("focused");
                } else {
                    var $nextItem = $('.focused').parent('li').next('li').children('a');
                    $($popover).children('a').removeClass('focused');
                    $nextItem.focus().addClass('focused');
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $($popover).children('a').removeClass('focused');
                    $('.navbar-header .user .popover').removeClass('show');
                    $('.user').attr('aria-expanded', 'false');
                } else {
                    var $prevItem = $('.focused').parent('li').prev('li').children('a');
                    $($popover).children('a').removeClass('focused');
                    $prevItem.focus().addClass('focused');
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );
    $('.navbar-header .user').on('mouseenter focusin', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').addClass('show');
            $('.user').attr('aria-expanded', 'true');
        }
    });

    $('.navbar-header .user').on('mouseleave', function () {
        $('.navbar-header .user .popover').removeClass('show');
        $('.user').attr('aria-expanded', 'false');
    });
    $('body').on('click', '#myaccount', function (e) {
        e.preventDefault();
    });
};
