'use strict';

module.exports = function () {
    $('.c-email-signup__form').on('submit', function (event) {
        event.preventDefault();
        $('.c-email-signup-validation-error-message').addClass('invisible');
        $('.c-email-signup-error-message').addClass('invisible');

        var userEmail = $('.c-email-signup__input').val();
        var regex= /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/; // eslint-disable-line space-infix-ops

        if (userEmail === '' || !regex.test(userEmail)) {
            $('#hpEmailSignUp').trigger('focus');
            return false;
        }

        $.spinner().start();
        var url = $(this).attr('action') + '?hpEmailSignUp=' + encodeURIComponent(userEmail);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    $('.js-email-signup-form').hide();
                    $('.c-email-signup-success-message').removeClass('d-none');
                }

                $.spinner().stop();
            },
            error: function (err) {
                $('.c-email-signup__validation-error-message').removeClass('invisible');
                $.spinner().stop();
            }
        });
        return true;
    });
};

