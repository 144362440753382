'use strict';

/**
 * Toggle elements' visibility
 */

// Initial loading of toggle component

function openModal(header, body) {
    $('#contentModal').remove();
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="contentModal" role="dialog" aria-modal="true" aria-label="promotions">'
        + '<div class="modal-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + header
        + '    <button type="button" class="js-close close pull-right" data-dismiss="modal" aria-label="Close">'
        + '        &times;<span class="sr-only">Close</span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body">'
        + ''
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    var $contentModal = $('#contentModal');
    $contentModal.find('.modal-body').append(body);
    $contentModal.modal('show');
    $($contentModal).on('shown.bs.modal', function () {
        $contentModal.find('.js-close').focus();
    });
}

module.exports = {
    init: function () {
        $(document).on('click', '.js-toggle', function (e) {
            e.preventDefault();
            $(this).find('.js-toggle-content').toggle();
        });
        $(document).on('click', '.js-toggle-modal', function (e) {
            e.preventDefault();
            // Add active class to link to return focus after modal close
            $(this).addClass('is-active');
            var body = $(this).find('.js-toggle-content div').clone();
            var header = $(this).attr('data-modal-header');
            openModal(header, body);
        });

        // this fixes the boostrap "nested" modal problems with backdrop
        // and scroll. Note: bootstrap does not officially support nested modals
        // ref: https://stackoverflow.com/questions/19305821/multiple-modals-overlay
        $(document).on('show.bs.modal', '.modal', function () {
            var zIndex = 1040 + (10 * $('.modal:visible').length);
            $('body').addClass('modal-open');
            $(this).css('z-index', zIndex);
            setTimeout(function () {
                $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
            }, 0);
        });
        $(document).on('hidden.bs.modal', '.modal', function () {
            if ($('.modal:visible').length) {
                $('body').addClass('modal-open');
            } else {
                $('body').removeClass('modal-open');
                // Bootstrap never removes the padding on body once it's applied.  Removing it here.
                $('body').css('padding-left', '0');
            }
            // Focus on initiating link after modal close and then remove active class
            $('.js-toggle-modal.is-active').trigger('focus');
            $('.js-toggle-modal').removeClass('is-active');
        });
    },
    socialShare: function () {
        $(document).on('click', '.js-social-share', function (e) {
            e.preventDefault();
            var shareLink = $(e.currentTarget).attr('href');
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'socialShare',
                socialNetwork: $(this).attr('data-social-network')
            });
            window.open(shareLink);
        });
    }
};
