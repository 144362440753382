'use strict';

/**
 * Image Carousel
 */

module.exports = function () {
    $('.js-image-carousel:not(.slick-initialized)').slick({
        autoplay: false,
        arrows: true
    });

    $('.js-content-image-carousel:not(.slick-initialized)').slick({
        dots: true
    });

    $('.js-content-image-carousel-offscreen:not(.slick-initialized)').slick({
        infinite: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2.5
                }
            },
            {
                breakpoint: 544,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.js-info-banner-carousel:not(.slick-initialized)').slick({
        adaptiveHeight: true,
        prevArrow: '.js-marketing-carousel-control-prev',
        nextArrow: '.js-marketing-carousel-control-next'
    });
};
