'use strict';

/**
 * Keyboard support and other tab helper functions
 */

var keyboardAccessibility = require('base/components/keyboardAccessibility');

function traverseDown($this, radioGroup) {
    if ($($this).parents('.js-tab-item').next('.js-tab-item').length !== 0 && $($this).hasClass('js-tab-link-vertical')) {
        if ($($this).is('input')) {
            $($this).parents('.js-tab-item').next('.js-tab-item').find('label')
            .trigger('click')
            .trigger('focus');
        } else {
            $($this).parent('.js-tab-item').next('.js-tab-item').find('.js-tab-link')
            .trigger('click')
            .trigger('focus');
        }
    } else {
        // eslint-disable-next-line no-lonely-if
        if ($($this).is('input')) {
            $(radioGroup).find('.js-tab-item:first').find('.c-form-element').addClass('c-form-element--selected');
            $(radioGroup).find('.js-tab-item:first').find('.js-tab-link').prop('checked', true)
            .trigger('focus');
            $($this).parent('.c-form-element').removeClass('c-form-element--selected');
        } else {
            $($this).parent('.js-tab-item').next('.js-tab-item').find('.js-tab-link')
            .trigger('click')
            .trigger('focus');
        }
    }
}

function traverseUp($this, radioGroup) {
    if ($($this).parents('.js-tab-item').prev('.js-tab-item').length !== 0 && $($this).hasClass('js-tab-link-vertical')) {
        if ($($this).is('input')) {
            $($this).parents('.js-tab-item').prev().find('label')
            .trigger('click');
        } else {
            $($this).parent('.js-tab-item').prev('.js-tab-item').find('.js-tab-link')
            .trigger('click')
            .trigger('focus');
        }
    } else {
        // eslint-disable-next-line no-lonely-if
        if ($($this).is('input')) {
            $(radioGroup).find('.js-tab-item:last').find('.c-form-element').addClass('c-form-element--selected');
            $(radioGroup).find('.js-tab-item:last').find('.js-tab-link').prop('checked', true)
            .trigger('focus');
            $($this).parent('.c-form-element').removeClass('c-form-element--selected');
        } else {
            $($this).parent('.js-tab-item').prev('.js-tab-item').find('.js-tab-link')
            .trigger('click')
            .trigger('focus');
        }
    }
}

module.exports = $(function () {
    var tabs = $('.js-tabs');
    var tabsList = tabs.find('ul').attr({
        role: 'tablist'
    });
    var radioGroup = tabs.find('.js-radio-group').attr({
        role: 'radiogroup'
    });
    var tabItem = tabs.find('.js-tab-link');

    $(tabItem).each(
        function (a) {
            var tab = $(this);
            var tabParent = tab.parents('.js-tabs');
            var tabId;

            if (tab.attr('href')) {
                tabId = 'tab-' + tab.attr('href').slice(1);
            }

            tabsList.children().not('.active').find(tabItem).attr({
                id: tabId,
                role: 'tab',
                'aria-selected': 'false',
                tabindex: '-1'
            })
            .parent()
            .attr('role', 'presentation');

            $(tabs).find('.tab-pane').eq(a).attr('aria-labelledby', tabId);

            tab.on('click', function (e) {
                if (tab.attr('href')) {
                    e.preventDefault();
                }

                $(tabParent).find('.js-tab-link').removeClass('active')
                .attr({
                    'aria-selected': 'false',
                    tabindex: '-1'
                });

                $(parent).find('.tab-pane:visible').attr('aria-hidden', 'true').hide();

                $(parent).find('.tab-pane').eq(tab.parent().index()).attr('aria-hidden', 'false')
                .show();

                tab.attr({
                    'aria-selected': 'true',
                    tabindex: '0'
                }).parent().addClass('active');
                tab.trigger('focus');
            });
        }
    );

    keyboardAccessibility(tabItem,
        {
            39: function () { // right
                var $this = this;
                traverseDown($this, radioGroup);
            },
            37: function () { // left
                var $this = this;
                traverseUp($this, radioGroup);
            },
            38: function () { // up
                var $this = this;
                traverseUp($this, radioGroup);
            },
            40: function () { // down
                var $this = this;
                traverseDown($this, radioGroup);
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $(tabs).find('.tab-pane.active').attr('aria-hidden', 'false').show();

    $(tabsList).find('li').find(' > .active')
    .attr({
        'aria-selected': 'true',
        tabindex: '0'
    });

    // Specific behavior for account nav tabs due to UX and hidden tab elements
    $('.js-account-nav-tabs').find('.nav-link.active').parents('.nav-item').addClass('active');

    $('.js-account-nav-tabs .nav-link').on('click', function () {
        $('.js-account-nav-tabs .nav-link').parents('.nav-item').removeClass('active');
        $('.js-account-nav-tabs').removeClass('nav-tabs--register-active');
        $(this).parents('.nav-item').addClass('active');
        if ($(this).hasClass('nav-link--register')) {
            $(this).parents('.js-account-nav-tabs').addClass('nav-tabs--register-active');
        }
    });
});
