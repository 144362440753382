'use strict';
var matchBreakpoints = require('../util/matchBreakpoints');

module.exports = {
    sizes: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    collapseSizes() {
        var sizes = this.sizes || ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']; // Include fallback array

        // Base toggling as well as handling the is-active class
        sizes.forEach(function (size) {
            var selector = '.collapsible-' + size + ' .title, .collapsible-' + size + '>.card-header';

            $('body').on('click', selector, function (e) {
                e.preventDefault();
                $(this).parents('.collapsible-' + size).toggleClass('active');
                if ($(this).parents('.collapsible-' + size).hasClass('active')) {
                    $(this).attr('aria-expanded', true);
                } else {
                    $(this).attr('aria-expanded', false);
                }
                $('.js-product-details-tabs').trigger('collapsible:toggleVisibility');
            });
        });
    },
    footerLinks() {
        $('body')
            .on('click', '.st-global-footer__section .title a, .st-global-footer__section a.title', function (e) {
                if (!matchBreakpoints(['default', 'xs-up', 'sm-up', 'md-up'])) {
                    window.location = e.target.href;
                }
            });
    }
};
