'use strict';

var cart = require('base/cart/cart');

var updateMiniCart = true;
var minicartDrawer = $('.minicart').find('.js-minicart');

var exposeMinicart = function () {
    var url = $('.minicart').data('action-url');
    var count = parseInt($('.minicart .minicart-quantity').text(), 10);

    if (count !== 0 && $('.minicart .js-minicart.show').length === 0) {
        if (!updateMiniCart) {
            $('.minicart .js-minicart').addClass('show');
            return;
        }

        $(minicartDrawer).spinner().start();
        $(minicartDrawer).modal('show');

        $.get(url, function (data) {
            $(minicartDrawer).empty();
            $(minicartDrawer).append(data);
            updateMiniCart = false;
            $.spinner().stop();
        });
    }
};

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.cart.resources.minicartCountOfItems,
                title: count.cart.resources.minicartCountOfItems
            });
        }
    });

    $('.minicart').on('focusin', function () {
        // Customization: if search panel is exposed when minicart gains focus, close the search panel and update the ARIA attribute
        $('html').removeClass('is-site-search-active');
        $('.js-site-search').attr('aria-expanded', 'false');
    });

    $('body').on('click', '.js-minicart-close', function () {
        $(minicartDrawer).modal('hide');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);
        if (count !== 0) {
            $('.minicart-link-no-items').removeClass('minicart-link-no-items').addClass('minicart-link');
            $('.c-minicart__quantity').attr('aria-label', count + ' items in cart');
        }
        // If there are errors, don't show the minicart
        if (!$('.add-to-cart-messages .alert-danger').length && !($('#jsDetailErrorModal').length)) {
            exposeMinicart();
        }
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
    // If this is the minicart remove product modal, return focus to the minicart link
    $('body').on('click', '.minicart .js-cart-remove-item', function () {
        $('.minicart-link').trigger('focus');
    });
};
