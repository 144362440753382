'use strict';

module.exports = function (element) {
    var position = element && element.length ? element.offset().top : 0;
    $('html, body').animate({
        scrollTop: position
    }, 500);
    if (!element) {
         // Locate first focusable element and focus on it, remove the outline so it doesn't look like a display issue
        $('.js-global-header-wrapper .brand').trigger('focus').css('outline', 'none');
    }
};
