var xDown = null;

function getTouches(evt) {
    return evt.touches || evt.originalEvent.touches;
}

function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
}

function handleTouchMove(evt) {
    if (!xDown) {
        return;
    }

    var xUp = evt.touches[0].clientX;
    var xDiff = xDown - xUp;

    if (xDiff < -10) {
        $('#inStoreInventoryModal').modal('hide');
        $('#customerLoginDrawer').modal('hide');
    }

    /* reset values */
    xDown = null;
}

document.addEventListener('touchstart', (e) => {
    let inStoreModal = $('#inStoreInventoryModal');
    let loginModal = $('#customerLoginDrawer');
    if (!inStoreModal.length && !loginModal.length) {
        return;
    }

    handleTouchStart(e);
}, false);

document.addEventListener('touchmove', (e) => {
    let inStoreModal = $('#inStoreInventoryModal');
    let loginModal = $('#customerLoginDrawer');
    if (!inStoreModal.length && !loginModal.length) {
        return;
    }

    handleTouchMove(e);
}, false);
