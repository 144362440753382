/* global $ */
'use strict';
const clientSideValidation = require('app_rws/components/clientSideValidation');

var $errorBlock = $('.js-form-error');

function setError(errorMessage, focusState) {
    $errorBlock.text(errorMessage);
    $errorBlock.show();
    var $form = $($errorBlock).parents('form');
    // Check to see if we need to trigger a focus state
    if ($(focusState)) {
        $form.find('.js-form-error').prev('input').trigger('focus');
    }
    $('body, html').animate({
        scrollTop: $($form).offset().top
    }, '1000');
}

function clearErrors() {
    $errorBlock.text('');
    $errorBlock.hide();
}

module.exports = {
    submitForm: function () {
        $('.js-submit-form:not(:disabled)').on('click', function (e) {
            var $form = $(this).parents('form');

            var url = $form.attr('action');
            var source = $form.attr('id') || 'formsubmit';

            e.preventDefault();
            clearErrors();
            $.spinner().start();

            var isFormValid = clientSideValidation.functions.validateForm($form[0], e);

            try {
                if ('grecaptcha' in window && isFormValid) {
                    window.grecaptcha.execute(
                        $('[data-sitekey]').data('sitekey'),
                        {
                            action: source
                        }
                    ).then(function (token) {
                        url += '?gRecaptchaToken=' + token;
                        $.ajax({
                            method: 'POST',
                            url: url,
                            dataType: 'json',
                            data: $form.serialize(),
                            success: function (data) {
                                // success handling here
                                if (data.error) {
                                    setError(data.errorMessage, data.focusState);
                                    $.spinner().stop();
                                } else {
                                    location.href = data.redirectUrl;
                                }
                            },
                            error: function () {
                                $.spinner().stop();
                            }
                        });
                    });
                } else {
                    $.spinner().stop();
                }
            } catch (err) {
                $.spinner().stop();
            }
        });
    }
};
